<template>
    <v-container class="ma-0 pa-0">
        <v-row class="py-10 px-8">
            <strong>Filtros</strong>

            <div class="mt-4">
                Fecha
                <v-menu v-model="menuFrom" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px" >
                    <template v-slot:activator="{ on }">
                        <v-text-field @keydown.enter="filter()" v-model="payroll.dateFrom" label="Desde" clearable prepend-icon="mdi-calendar-today" readonly v-on="on"></v-text-field>
                    </template>
                    <v-date-picker color="primary" v-model="payroll.dateFrom" @input="menuFrom = false"></v-date-picker>
                </v-menu>
                <v-menu v-model="menuTo" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px" >
                    <template v-slot:activator="{ on }">
                        <v-text-field @keydown.enter="filter()" v-model="payroll.dateTo" label="Hasta" clearable prepend-icon="mdi-calendar" readonly v-on="on"></v-text-field>
                    </template>
                    <v-date-picker color="primary" v-model="payroll.dateTo" @input="menuTo = false"></v-date-picker>
                </v-menu>
            </div>

            <v-autocomplete @keydown.enter="filter()" v-model="payroll.user_id" :items="userLists" item-text="name" item-value="id" attach chips label="Personal" multiple>
            </v-autocomplete>

            <v-autocomplete @keydown.enter="filter()" v-model="payroll.job_position" :items="positions" attach chips label="Área" multiple>
            </v-autocomplete>

            <v-autocomplete @keydown.enter="filter()" v-model="payroll.sub_job_position" :items="subPositions" attach chips label="Puesto" multiple>
            </v-autocomplete>

            <v-text-field @keydown.enter="filter()" v-model="payroll.notes" label="Notas"></v-text-field>

            <!--div class="mt-4">
                Fecha de creación 
                <v-menu v-model="menuFrom" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px" >
                    <template v-slot:activator="{ on }">
                        <v-text-field @keydown.enter="filter()" v-model="payroll.dateCreateFrom" label="Desde" clearable prepend-icon="mdi-calendar-today" readonly v-on="on"></v-text-field>
                    </template>
                    <v-date-picker color="primary" v-model="payroll.dateCreateFrom" @input="menuFrom = false"></v-date-picker>
                </v-menu>
                <v-menu v-model="menuTo" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px" >
                    <template v-slot:activator="{ on }">
                        <v-text-field @keydown.enter="filter()" v-model="payroll.dateCreateTo" label="Hasta" clearable prepend-icon="mdi-calendar" readonly v-on="on"></v-text-field>
                    </template>
                    <v-date-picker color="primary" v-model="payroll.dateCreateTo" @input="menuTo = false"></v-date-picker>
                </v-menu>
            </div>
            <div class="mt-4">
                Última Actualización 
                <v-menu v-model="menuFrom2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px" >
                    <template v-slot:activator="{ on }">
                        <v-text-field @keydown.enter="filter()" v-model="payroll.dateUpdateFrom" label="Desde" clearable prepend-icon="mdi-calendar-today" readonly v-on="on"></v-text-field>
                    </template>
                    <v-date-picker color="primary" v-model="payroll.dateUpdateFrom" @input="menuFrom2 = false"></v-date-picker>
                </v-menu>
                <v-menu v-model="menuTo2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px" >
                    <template v-slot:activator="{ on }">
                        <v-text-field @keydown.enter="filter()" v-model="payroll.dateUpdateTo" label="Hasta" clearable prepend-icon="mdi-calendar" readonly v-on="on"></v-text-field>
                    </template>
                    <v-date-picker color="primary" v-model="payroll.dateUpdateTo" @input="menuTo2 = false"></v-date-picker>
                </v-menu>
            </div-->

        </v-row>
        <v-card style="position:absolute; bottom:0; width:100%;" tile color="primary">
            <v-list-item link @click="filter()" dark>
                <v-list-item-content>
                    <strong>Filtrar (0)</strong>
                </v-list-item-content>
            </v-list-item>    
        </v-card>
    </v-container>
</template>
<script>
  export default {
    props:{
        company:Object
    },
    data: () => ({
        menuTo:false,
        //menuTo2:false,
        menuFrom:false,
        //menuFrom2:false,
        payroll:{
            notes:'',
            user_id:'',
            sub_job_position:'',
            job_position:'',
            dateTo:'',
            dateFrom:'',
            /*
            dateCreateFrom:'',
            dateCreateTo:'',
            dateUpdateFrom:'',
            dateUpdateTo:'',
            */
        },
        subPositions:[
            'Administración',
            'Asistente dirección',
            'Contabilidad', 
            'Almacén',
            'Calidad',
            'Logística', 
            'Montacargas',
            'Cobranza',
            'Compras', 
            'Ecommerce',
            'Publicidad',
            'Ventas', 
            'Ventas Industria',
            'App',
            'Trapo'
        ],
        positions:[
            'Administración',
            'Almacén',
            'Chofer Ejecutivo',
            'Chofer Repartidor',
            'Comercial',
            'Dirección',
            'Producción',
            'Sistemas'
        ],
    }),
    computed: {
        userLists(){
            return this.$store.state.user.users;
        }, 
    },
    created () {
        
    },
    methods: {
        filter(){
            this.$emit("filtersPayroll", this.payroll);
        }
    }
  }
</script>