<template>
    <v-container style="max-width:100vw;">
        <!-- Filtros -->
        <v-navigation-drawer style="top: 0px; max-height:100vh!important;" right v-model="filters" :clipped="$vuetify.breakpoint.lgAndUp" app>
            <filterPayrolls @filtersPayroll="filtersPayroll"/>
        </v-navigation-drawer>
        <!-- Contenedor -->
        <v-data-table :headers="headers" :items="payrolls" class="elevation-0 px-6 py-4">
            <!-- Header -->
            <template v-slot:top>
                <v-toolbar flat >
                    <v-toolbar-title>Nominas</v-toolbar-title>
                    <v-btn icon>
                        <v-icon @click="openFilter()">mdi-filter</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn icon v-show="permissions('download')">
                        <v-icon @click="exportExcel">mdi-download</v-icon>
                    </v-btn>
                    <v-btn icon v-show="permissions('printPayrolls')">
                        <v-icon @click="printPayrolls">mdi-printer</v-icon>
                    </v-btn>
                </v-toolbar>
            </template>
            <!-- Acciones por fila -->
            <template v-slot:[`item.actions`]="{ item }">
                <v-menu bottom left v-if="permissions('editPayrolls')==true || permissions('deletePayrolls')==true">
                    <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                    </template>
                    <v-list style="font-size:13px;">
                        <v-list-item @click="editItem(item.id)"  v-show="permissions('editPayrolls')">
                            <v-icon small class="mr-2">
                                mdi-pencil
                            </v-icon>
                            Editar
                        </v-list-item>
                        <v-list-item @click="deleteItem(item)"  v-show="permissions('deletePayrolls')">
                            <v-icon small class="mr-2">
                                mdi-delete
                            </v-icon>
                            Eliminar
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
            <!-- Creación -->
            <template v-slot:[`item.created_at`]="{ item }">
                {{item.created_at.slice(0, 10)}}
            </template>
            <!-- Actualización -->
            <template v-slot:[`item.updated_at`]="{ item }">
                {{item.updated_at.slice(0, 10)}}
            </template>
            <!-- Tabla sin información -->
            <template v-slot:no-data>
                No existen registros de nomina aún
            </template>
        </v-data-table>
        <!-- Crear actividad -->
        <v-dialog v-model="createDialog" max-width="700px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn bottom color="#e74919" dark fab fixed right v-bind="attrs" v-on="on">
                <v-icon color="white">  mdi-plus </v-icon>
            </v-btn> 
          </template>
          <create @closeCreateDialogPayroll="closeCreateDialogPayroll"/>
        </v-dialog>
        <!-- Editar actividad -->
        <v-dialog v-model="editDialog" max-width="700px">
          <edit v-bind:payroll="payroll" @closeEditDialogPayroll="closeEditDialogPayroll"/>
        </v-dialog>
        <!-- Dialogo confirmación de eliminación -->
        <div class="text-center">
            <v-bottom-sheet  v-model="sheet" inset>
                <v-sheet class="text-center" height="150px">
                    <div class="pt-6">
                    ¿Seguro que deseas borrar esta nomina?
                    </div>
                    <v-btn class="mt-4" text color="error" @click="deletePayroll()">
                    Eliminar
                    </v-btn>
                    <v-btn class="mt-4" text color="grey" @click="cancel()">
                    Cancelar
                    </v-btn>
                </v-sheet>
            </v-bottom-sheet>
        </div>
        <v-snackbar :color="snackbar.color" v-model="snackbar.show">
            {{ snackbar.message }}
        </v-snackbar>
    </v-container>
</template>

<script>
import axios from "axios";
import XLSX from 'xlsx';
import Filter from "../payrolls/filter"
import Create from "../payrolls/create"
import Edit from "../payrolls/edit"
export default {
    components: {
        'filterPayrolls':Filter,
        'create':Create,
        'edit':Edit,
    }, 
    data: () => ({
        
        payroll:'',
        sheet2: false,
        sheet: false,
        filters: false,
        editDialog: false,
        createDialog: false,
        deleteId:'',
        payrolls: '',
        snackbar: {
            show: false,
            message: null,
            color: null
        },
    }),
    computed: {
        currentUser:{
            get(){
                return this.$store.state.currentUser.user;
            }
        },
        headers(){ 
            this.payrolls = this.payrollsLists
            return [
            { text: 'Fecha', value: 'date' },
            { text: 'Personal', value: 'user_id' },
            { text: 'Área', value: 'job_position' },
            { text: 'Puesto', value: 'sub_job_position'},
            { text: 'IMSS', value: 'imss' },
            { text: 'Infonavit', value: 'infonavit' },
            { text: 'Monto', value: 'amount' },
            { text: 'Sueldo Diario', value: 'daily_salary' },
            { text: 'Tiempo Extra', value: 'extra_time' },
            { text: 'Premio Producción', value: 'production_award' },
            { text: 'Premio Puntualidad', value: 'punctuality_award' },
            { text: 'Premio Rendimiento', value: 'performance_award' },
            { text: 'Faltas', value: 'absence' },
            { text: 'Notas', value: 'notes' },
            { text: 'Creación', value: 'created_at' },
            { text: 'Creador', value: 'created_by_user_id' },
            { text: 'Edición', value: 'updated_at' },
            { text: 'Editor', value: 'last_updated_by_user_id' },
            { value: 'actions', sortable: false, align: 'end', },
        ]},
        payrollsList(){
            return this.$store.state.payroll.payrolls
        },
        payrollsLists(){
            var payrolls = this.$store.state.payroll.payrolls
            payrolls = payrolls.map(id=>{
                return{
                    id:id.id,
                    imss:(id.imss*1).toLocaleString('es-MX', { style: 'currency', currency: 'MXN',}),
                    infonavit:(id.infonavit*1).toLocaleString('es-MX', { style: 'currency', currency: 'MXN',}),
                    extra_time:(id.extra_time*1).toLocaleString('es-MX', { style: 'currency', currency: 'MXN',}),
                    production_award:(id.production_award*1).toLocaleString('es-MX', { style: 'currency', currency: 'MXN',}),
                    punctuality_award:(id.punctuality_award*1).toLocaleString('es-MX', { style: 'currency', currency: 'MXN',}),
                    performance_award:(id.performance_award*1).toLocaleString('es-MX', { style: 'currency', currency: 'MXN',}),
                    absence:(id.absence*1).toLocaleString('es-MX', { style: 'currency', currency: 'MXN',}),
                    job_position:this.userRol(id.user_id),
                    sub_job_position:this.userSubRol(id.user_id),
                    daily_salary:(id.amount/30).toLocaleString('es-MX', { style: 'currency', currency: 'MXN',}),
                    sum : ((id.extra_time*1)+(id.production_award*1)+(id.punctuality_award*1)+(id.performance_award*1)).toLocaleString('es-MX', { style: 'currency', currency: 'MXN',}), 
                    rest: ((id.absence*1)+(id.infonavit*1)+(id.imss*1)).toLocaleString('es-MX', { style: 'currency', currency: 'MXN',}), 
                    date:id.date,
                    amount:(id.amount*1).toLocaleString('es-MX', { style: 'currency', currency: 'MXN',}),
                    notes:id.notes,
                    total: (id.amount+((id.extra_time*1)+(id.production_award*1)+(id.punctuality_award*1)+(id.performance_award*1)) - ((id.absence*1)+(id.infonavit*1)+(id.imss*1))).toLocaleString('es-MX', { style: 'currency', currency: 'MXN',}),
                    created_by_user_id:this.userName(id.created_by_user_id),
                    last_updated_by_user_id:this.userName(id.last_updated_by_user_id),
                    user_id:id.user_id,
                    created_at:id.created_at,
                    updated_at:id.updated_at,
                }
            });
            return payrolls
        },
    },
    methods: {
        printPayrolls(){
            localStorage.setItem('nominas', JSON.stringify(this.payrolls));
            window.open("/");
        },
        openFilter(){
            if(this.filters == false){
                this.$emit("closeDrawer", false);
                this.filters = true
            }else{
                this.$emit("closeDrawer", true);
                this.filters = false
            }
        },
        permissions(permission){
            if(this.currentUser.id==1){
                return true
            }else if(this.currentUser.permissions!=undefined){
                if(this.currentUser.permissions.includes(permission)){
                    return true
                }else{
                    return false
                }
            }else{
                return false
            }
        },
        removeDuplicates(originalArray, prop) {
            var newArray = [];
            var lookupObject  = {};
            for(var i in originalArray) {
                lookupObject[originalArray[i][prop]] = originalArray[i];
            }
            for(i in lookupObject) {
                newArray.push(lookupObject[i]);
            }
            return newArray;
        },
        lowerCase(text){
            if(text!=null&&text!=undefined&&text!=''){
                return text.toLowerCase()
            }else{
                return ' '
            }
        },
        filtersPayroll: function(params) {
            var filterPayroll = this.payrollsLists
            if(params.user_id!=''&&params.user_id!=undefined&&params.user_id!=null){
                var uno = filterPayroll.filter(payroll=>payroll.user_id == params.user_id[0])
                for(var i=1; i<params.user_id.length; i++){
                    uno=uno.concat(filterPayroll.filter(payroll=>payroll.user_id == params.user_id[i]))   
                }
                filterPayroll = this.removeDuplicates(uno, "id");
            }
            if(params.job_position!=''&&params.job_position!=undefined&&params.job_position!=null){
                var dos = filterPayroll.filter(payroll=>payroll.job_position == params.job_position[0])
                for(var i=1; i<params.job_position.length; i++){
                    dos=dos.concat(filterPayroll.filter(payroll=>payroll.job_position == params.job_position[i]))   
                }
                filterPayroll = this.removeDuplicates(dos, "id");
            }
            if(params.sub_job_position!=''&&params.sub_job_position!=undefined&&params.sub_job_position!=null){
                var tres = filterPayroll.filter(payroll=>payroll.sub_job_position == params.sub_job_position[0])
                for(var i=1; i<params.sub_job_position.length; i++){
                    tres=tres.concat(filterPayroll.filter(payroll=>payroll.sub_job_position == params.sub_job_position[i]))   
                }
                filterPayroll = this.removeDuplicates(tres, "id");
            }
            if(params.notes!='' && params.notes!=null && params.notes!=undefined){
                filterPayroll=filterPayroll.filter(payroll=>this.lowerCase(payroll.notes).includes(params.notes.toLowerCase()))
            }
            if(params.dateFrom!='' && params.dateFrom!=null && params.dateFrom!=undefined){
                filterPayroll=filterPayroll.filter(payroll=>new Date(payroll.date) >= new Date(params.dateFrom))
            }
            if(params.dateTo!='' && params.dateTo!=null && params.dateTo!=undefined){
                var dateTwo = new Date(new Date(params.dateTo).setDate(new Date(params.dateTo).getDate() + 1))
                filterPayroll=filterPayroll.filter(payroll=>new Date(payroll.date) <= dateTwo)
            }
            this.payrolls = filterPayroll
        },
        check(items, param){
            if(items!=undefined&&items!=''&&items!=null){
                for(var i=0; i<items.length; i++){
                    if(items[i].item == param){
                        return 'contain'
                    }
                }  
            }
        },
        closeEditDialogPayroll: function(params) {
            this.editDialog = false;
            this.$store.dispatch('payroll/getPayrolls')
        },
        closeCreateDialogPayroll: function(params) {
            this.createDialog = false;
            this.$store.dispatch('payroll/getPayrolls')
        },
        exportExcel: function () {
            let data = XLSX.utils.json_to_sheet(this.payrolls)
            const workbook = XLSX.utils.book_new()
            const filename = 'Lista de Cobranzas'
            XLSX.utils.book_append_sheet(workbook, data, filename)
            XLSX.writeFile(workbook, `${filename}.xlsx`)
        },
        deletePayroll(){
            axios.delete("https://intenbackend.unocrm.mx/api/v1/payroll/delete/"+this.deleteId).then(response => {
                this.deleteId = ''
                this.sheet = false
                this.$store.dispatch('payroll/getPayrolls')
            }).catch(error => {
                this.snackbar = {
                    message: error.response.data.message,
                    color: 'error',
                    show: true
                }
            });
        },
        cancel(){
            this.deleteId = ''
            this.sheet = false
            this.sheet2 = false
        },
        deleteItem (item) {
            this.deleteId = item.id
            this.sheet = true
        },
        editItem(id){
            this.payroll = this.payrollsList.filter(payroll=>payroll.id == id)[0]
            this.editDialog = true
        },
        userName(id){
            return this.$store.state.user.users.filter(user=>user.id == id).map(user => user.name + ' ' + user.last)[0]
        },
        userRol(id){
            return this.$store.state.user.users.filter(user=>user.id == id).map(user => user.job_position)[0]
        },
        userSubRol(id){
            return this.$store.state.user.users.filter(user=>user.id == id).map(user => user.sub_job_position)[0]
        },
    }
}
</script>
<style>
    .bordercolor{
        width: 3px;
        content: ' ';
        height: 47px;
        position: absolute;
        border-radius:5px 0px 0px 5px;
    }
    @media(min-width: 980px){
        .bordercolor{
            margin-top: -13px;
            margin-left: -19px;
        }
    }
    @media(max-width: 980px){
        .bordercolor {
            height: 360px;
            left: 9px;
        }
    }
    .v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
        box-shadow: none!important;
        background: #f7f7f7;
    }
</style>